import React, { createContext, PropsWithChildren, useContext, useState } from 'react';

export interface ApplicationContext {
  refresh: () => void;
}

const initialValue: ApplicationContext = {
  refresh: () => undefined,
};

const applicationContext = createContext(initialValue);

export const ApplicationContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [version, setVersion] = useState<number>(0);

  const value: ApplicationContext = {
    // This will fully rerender widget and kill all the hidden states and caches.
    refresh: () => setVersion((version) => version + 1),
  };

  return (
    <applicationContext.Provider key={version} value={value}>
      {children}
    </applicationContext.Provider>
  );
};

export const useApplication = () => useContext(applicationContext);
