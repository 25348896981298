import React from 'react';
import { ErrorState, ErrorViews } from '@guest-widgets/shared/src/components/ErrorViews';
import { useApplication } from '@guest-widgets/shared/src/contexts/appplicationContext/applicationContext';

import { useSettings } from '../contexts/settingsContext/settingsContext';
import { WidgetType } from '../contexts/settingsContext/settings';

import Booking from './Booking';
import Catalog from './Catalog';
import Product from './Product';
import { InvalidContent } from './InvalidContent';

export const ContentWidget = () => {
  const {
    widgetType: content,
    loadingState: { isError },
  } = useSettings();

  const { refresh } = useApplication();

  if (isError) return <ErrorViews type={ErrorState.UnableToLoad} onClick={() => refresh()} />;

  const contentMapper: Record<WidgetType, JSX.Element> = {
    Booking: <Booking />,
    Catalog: <Catalog />,
    Product: <Product />,
  };

  // Validate content to give feedback in case of wrong configuration, avoiding blink effect when loading
  return content ? contentMapper[content] || <InvalidContent /> : null;
};
